
<template>
  <div class="map_content" :style="mapStyle">
    <div id="container"></div>
  </div>
</template>

<script>
import AMap from 'AMap'
import getPlace from '@/common/utils/getPlace.js'
export default {
  name: 'searchMap',
  components: {},
  props: {
    locatePointShow: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data () {
    return {
      trailIcon: [
        require('@/assets/images/map/trailStart.png'), //起点
        require('@/assets/images/map/trailEnd.png'), //终点
        require('@/assets/images/map/trail.png') //停车点
      ],
      map: null,
      mouseTool: null,
      markers: [],
    }
  },
  watch: {
    /** 点击轨迹回放表格中位置信息 显示到地图上 */
    locatePointShow (val) {
      this.map.remove(this.markers)
      var marker = new AMap.Marker({
        position: new AMap.LngLat(val.longitude, val.latitude),
        zIndex: 9999
      })
      marker.setMap(this.map)
      this.markers.push(marker);      
      const self = this
      let area = ''
      getPlace(val.longitude, val.latitude, true).then((res) => {  
        area = res;      
        let content = `<div >时间：<span>${val.alarmSignTime}</span></div>`
        let infoWindow = new AMap.InfoWindow({
          anchor: "top-left",
          content: `<div class=trailBoard>
            ${content}
            <div style=color:#059370;>${area}</div>
            </div>
            `
        })
        infoWindow.open(self.map, [val.longitude, val.latitude])
      })
      setTimeout(() => {
        marker.setMap(self.map)
      }, 300)
      // self.map.setCenter([val.longitude, val.latitude])
    },
  },
  methods: {
    /** 加载地图和相关组件 */
    loadmap () {
      this.map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        zoom: 12,
        resizeEnable: true,
        expandZoomRange: true,
        center: [105.829757, 32.433668],//登录的时候获取的用户所在位置经纬度
        features: ['bg', 'road', 'building', 'point']
      })
    },
    initPathSimplifier () {
      var self = this
      AMapUI.load(
        ['ui/misc/PathSimplifier', 'lib/$'],
        function (PathSimplifier, $) {
          if (!PathSimplifier.supportCanvas) {
            alert('当前环境不支持 Canvas！')
            return
          }
          if (window.pathSimplifierIns) {
            //通过该方法清空上次传入的轨迹
            window.pathSimplifierIns.setData([])
          }
          var pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            //autoSetFitView:false,
            map: self.map, //所属的地图实例
            getPath: function (pathData, pathIndex) {
              return pathData.path
            },
            getHoverTitle: function (pathData, pathIndex, pointIndex) {
              return null
            },
            renderOptions: {
              renderAllPointsIfNumberBelow: -1,
              pathTolerance: 0,
              keyPointTolerance: -1,
              pathLineStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              pathLineHoverStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              pathLineSelectedStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              dirArrowStyle: {
                stepSpace: 80,
                strokeStyle: '#ffffff',
                lineWidth: 2
              }
            }
          })
          window.pathSimplifierIns = pathSimplifierIns
          //设置数据
          pathSimplifierIns.setData([
            {
              name: '路线0',
              path: self.pointArr
            }
          ])
          pathSimplifierIns.setSelectedPathIndex(0)
          pathSimplifierIns.on('pointClick', function (e, info) {
            // console.log('Click: ' + self.trailArr[info.pointIndex].gt)
          })
          //对第一条线路（即索引 0）创建一个巡航器
          // let image = PathSimplifier.Render.Canvas.getImageContent(require('@/assets/images/map/car6.png'), onload, onerror)
          var pathNavigatorStyles = [
            {
              width: 18,
              height: 18,
              strokeStyle: '#0B4FD5',
              fillStyle: '#0B4FD5',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            },
            {
              width: 18,
              height: 18,
              strokeStyle: 'red',
              fillStyle: 'red',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            }
          ]
          function extend (dst) {
            if (!dst) {
              dst = {}
            }
            var slist = Array.prototype.slice.call(arguments, 1)
            for (var i = 0, len = slist.length; i < len; i++) {
              var source = slist[i]
              if (!source) {
                continue
              }
              for (var prop in source) {
                if (source.hasOwnProperty(prop)) {
                  dst[prop] = source[prop]
                }
              }
            }
            return dst
          }
          self.navgtr = null
        }
      )
    },
    onTrailPlay (row) {
      this.map.clearMap()
      let self = this;
      if (row.positionDataDTOList && row.positionDataDTOList.length !== 0) {
        var arr = []
        var objArr = []
        var positions = [row.positionDataDTOList[0]] // 轨迹de开始，停车，结束点
        for (let i = 0; i < row.positionDataDTOList.length; i++) {
          const element = row.positionDataDTOList[i]
          arr.push([element.longitude || element.lg, element.latitude || element.lt])
          objArr.push({
            longitude: element.longitude || element.lg,
            latitude: element.latitude || element.lt
          })
          if (element.pt && (i !== 0 || i !== row.positionDataDTOList.length - 1)) {
            positions.push(element)
          }
        }
        if (row.positionDataDTOList.length > 1) {
          positions.push(row.positionDataDTOList[row.positionDataDTOList.length - 1])
        }
        self.trailArr = [...row.positionDataDTOList] // 播放轨迹表单显示
        self.pointArr = arr // 轨迹线路
        // self.pathDetails = objArr // 点击保存线路
        self.initPathSimplifier()
        self.drawLine(self.pointArr, positions) // 展示线路  第二个参数:地图是否setFitView
      } else {
        self.trailArr = [] // 播放轨迹表单显示清空
        self.pointArr = [] // 轨迹线路清空
        // self.initPathSimplifier()
        pathSimplifierIns.hide();
        // self.map.clearMap()
        self.$message({
          message: '暂无轨迹数据',
          type: 'warning'
        })
      }
    },
    /* 轨迹回放 绘制线路 */
    drawLine (arr, positions) {
      let self = this
      // 清除地图
      let polylines = []
      // 只有第一次进来的时候将arr赋值给this.lineArr 同时获取剪裁后的点
      self.lineArr = arr
      for (var i = 1; i < arr.length - 1; i++) {
        //ac有值表示报警定位点--绘制红线
        if (self.trailArr[i].ac) {
          polylines.push(self.returnLine([arr[i - 1], arr[i]], 'red'))
        }
      }
      /** 起始点marker */
      positions.forEach((item, i) => {
        // console.log(item,i)
        let marker = new AMap.Marker({
          icon: new AMap.Icon({
            image:
              i === 0
                ? self.trailIcon[i]
                : i === positions.length - 1
                  ? self.trailIcon[1]
                  : self.trailIcon[2],
            imageSize: new AMap.Size(24, 30),
            size: new AMap.Size(30, 30) // 图标尺寸
          }),
          size: new AMap.Size(30, 30), // 图标尺寸
          zIndex: i === 0 || i === positions.length - 1 ? 200 : 100,
          position: [item.longitude || item.lg, item.latitude || item.lt]
        })
        marker.setMap(self.map)
      })
      // 缩放地图到合适的视野级别
      self.map.setFitView(polylines)
    },
    /** 创建polyline对象 */
    returnLine (spliceArr, color) {
      var polyline = new AMap.Polyline({
        map: this.map,
        path: spliceArr,
        borderWeight: 1,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeStyle: 'solid',
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        // geodesic: true, // 绘制大地线
        zIndex: 50
      })
      return polyline
    },

  },
  computed: {
    mapStyle: function () {
      return `height:${this.showTable ? '50vh' : '100%'}`
    }
  },
  created () { },
  mounted () {
    this.loadmap() // 加载地图和相关组件;
  },
  beforeDestroy () {
    this.map.clearMap()
    this.map = null
  },
}
</script>

<style lang="scss" scoped>
.map_content {
  position: relative;
  #container {
    width: 100%;
    height: 100%;
  }
  .toolBox {
    position: absolute;
    z-index: 1;
    top: 5px;
    border-width: 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    .search {
      margin-left: 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        text-indent: 10px;
      }
      input {
        width: 14vw;
        background: #fff;
        border-radius: 4px;
        padding-left: 5px;
        line-height: 32px;
        height: 32px;
        border: 1px solid #dcdfe6;
      }
      input:focus {
        outline: 1px solid #059370;
      }
    }
    & > span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }
}
</style>
